<template>
    <div>

        <GChart
                type="ColumnChart"
                :data="chartData"
                :options="chartOptions"
                :resizeDebounce="200"
                style="height: 150px;"
        />

       </div>
</template>

<script>
    import { GChart } from 'vue-google-charts/legacy'
    export default {
        name: "VueCharts",
        data () {
            return {
                // Array will be automatically processed with visualization.arrayToDataTable function
                chartData: [
                    ['Year', 'Degree'],
                    ['Smart', 40],
                    ['Irritated', 90],
                    ['Communicate', 60],
                ],
                chartOptions: {
                    chart: {
                    }
                }
            }
        },
        components: {
            GChart
        }
    }
</script>

<style scoped>

</style>