<template>
    <v-app id="inspire">

        <SideBar />
        <v-container>
            <v-app-bar color="rgba(0,0,0,0)" flat >

                <v-text-field label="Search ..."
                              class="pt-5"
                              filled  prepend-inner-icon="mdi-magnify"
                              dense solo flat background-color="grey lighten-4" rounded ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn icon dark class="">
                    <v-icon color="black">far fa-bell</v-icon>
                </v-btn>
                <v-select
                        class="mt-7"
                        :items="items"
                        v-model="select"
                        item-text="state"
                        color="black"
                        solo flat
                        style="max-width:7%;"
                        item-color="grey darken-2"

                ></v-select>

            </v-app-bar>
            <br/>

            <v-row>
                <v-col cols="12" sm="9">
                    <v-card class="mx-4 rounded-xl" color="grey lighten-3" flat max-height="200">
                        <v-row>
                            <v-col cols="12" sm="8">
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <div class="title-class ml-5">
                                                Smart <span class="title-class-pen">Pen</span>
                                            </div>

                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <div class="title-class-pen text-h6">When the Education Become an Art</div>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>


                                </v-list-item>

                                <v-card-actions>
                                    <v-btn class="px-3 ml-5" color="#1A7D8C" dark>
                                        View Details
                                    </v-btn>
                                </v-card-actions>
                            </v-col>
                            <v-col cols="12" sm="4">

                                <v-img src="SmartPen2.png" class="mt-n16 mr-5 mb-10" ></v-img>

                            </v-col>
                        </v-row>

                    </v-card>
                    <v-toolbar flat color="rgba(0,0,0,0)" dense class="mt-n1">

                    </v-toolbar>
                    <v-row class="mt-n5 " justify="center" >
                        <v-col cols="12" sm="3">
                            <v-card class="rounded-xl ml-4" color="grey lighten-3" flat max-width="190">
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h5 mb-1 mt-3 stat-title">
                                         <div class="stat-title">Stress</div>
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="mt-5 ml-4">
                                            <slot v-if="$stress_id === 1">
                                                <div class="green--text mouse--font">
                                                    17.5<span>%</span>
                                                </div>
                                            </slot>
                                            <slot v-else>
                                                <div class="red--text Mouse_Memoirs">
                                                    17.5<span class="prc--font">%</span>
                                                </div>
                                            </slot>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>

                                </v-list-item>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="5" >
                                <h1>
                                    <span class="text-h6 stat-title" style="justify-content: center">Personality</span>
                                </h1>
                                <VueCharts/>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-card class="rounded-xl mr-4 Mulish" color="grey lighten-3"  flat >
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h5 mb-1 mt-3">
                                         <div class="stat-title">Graphology</div>
                                        </v-list-item-title>
                                        <div v-for="(graph_menu,index) in graph_menu" :key="index" class="mouse--font ">
                                            <slot v-if= "graph_menu.id === 1">
                                                <v-list-item-subtitle class="mt-6 green--text mouse--font">{{graph_menu.desc1}}</v-list-item-subtitle>
                                            </slot>
                                            <slot v-else>
                                                <v-list-item-subtitle class="mt-6 red--text mouse--font">{{graph_menu.desc1}}</v-list-item-subtitle>
                                            </slot>
                                        </div>

                                    </v-list-item-content>

                                </v-list-item>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-card class="mx-4 rounded-xl pa-6 mt-5" color="grey lighten-3" flat max-height="70">
                        <v-toolbar flat color="rgba(0,0,0,0)" dense class="mt-n5">
                            <v-list-item three-line class="mt-2 notes">
                                <v-list-item-content>
                                    <v-list-item-subtitle class=" black--text notes">
                                        <slot>
                                            <v-icon color="black" class="ml-16">mdi-draw-pen</v-icon>
                                            Quality of Writing: <span class="green-color mr-3"> MEDIUM </span>
                                            <v-icon class="green-color ml-5">mdi-check</v-icon>
                                            <span class="green-color mr-3"> FOCUS MEDIUM    </span>
                                            <slot><v-icon color="red" class="ml-5">mdi-close</v-icon></slot>
                                            <span class="red--text"> SHE IS  TIRED</span>
                                        </slot>


                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <!--   <v-list class="d-flex align-center mt-4" color="grey lighten-3" >
                                    <v-list-item color="grey lighten-3">
                                            <v-list-item-title class="show_chart">
                                                <v-icon color="black">mdi-draw-pen</v-icon>
                                                Quality of Writing: <span class="green-color"> MEDIUM </span>
                                            </v-list-item-title>
                                        <v-list-item-title>
                                           <v-icon class="green-color">mdi-check</v-icon>
                                            <span class="green-color"> FOCUS MEDIUM    </span>
                                        </v-list-item-title>
                                        <v-list-item-title>
                                            <v-icon color="red">mdi-close</v-icon>
                                            <span class="red--text"> SHE IS  TIRED</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list> -->
                        </v-toolbar>

                    </v-card>
                </v-col>
                <v-col cols="12" sm="3" class="align-center">
                    <v-card class="rounded-xl pa-4 " color="grey lighten-3" flat>
                        <slot class="justify-center">
                            <v-img src="girl2.png" max-width="170" max-height="170" class="ml-8" ></v-img>

                            <v-spacer/>
                            <div class="name-class pa-2 ml-16">Abir Malek</div>
                            <v-spacer/>
                        </slot>
                        <v-app-bar  color="white"  class="rounded-xl" tile >
                            <v-icon color="black">mdi-phone</v-icon>

                            <strong class="subtitle black--text ml-2">
                                Parents phone number <span class="caption"><br>064578952458</span>
                            </strong>
                        </v-app-bar>
                        <v-app-bar  color="white" tile class="mt-3 rounded-xl">
                            <v-icon color="black">mdi-email</v-icon>
                            <strong class="subtitle black--text ml-2">
                                Parents address mail <span class="caption"><br>malek_y@gmail.com</span>
                            </strong>
                        </v-app-bar>
                        <v-app-bar  color="white" tile class="mt-3 rounded-xl">
                            <v-icon color="black">mdi-map-marker</v-icon>
                            <strong class="subtitle black--text ml-2">
                                House address <span class="caption"><br>62 Houses Street City Centre </span>
                            </strong>
                        </v-app-bar>
                        <v-toolbar flat color="rgba(0,0,0,0)" dense class="mt-1">
                        </v-toolbar>
                        <!--<div class="px-7">
                         <vueper-slides
                         :infinite="false"

                         class="no-shadow"
                         :visible-slides="3"
                         slide-multiple
                         :gap="3"
                         :slide-ratio="1 / 4"
                         :dragging-distance="200"
                         :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }"
                         >
                          <template #arrow-left>
                          <v-icon class="mr-n15">fas fa-chevron-left</v-icon>
                         </template>

                         <template #arrow-right>
                          <v-icon class="ml-n15">fas fa-chevron-right</v-icon>
                         </template>
                         <vueper-slide v-for="(slide, i) in slides" :key="i" :image="slide.src"  />

                       </vueper-slides>

                        </div> -->

                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
    import SideBar from '../components/SideBar'
    import VueCharts from "../components/VueCharts";
    //import { VueperSlides, VueperSlide } from 'vueperslides'
    export default {
        name: 'Home',
        data: () => ({
            select: { state: 'EN' },
            items: ['EN', 'FR', 'AR'],
            text: 'weekly',

            width: 2,
            radius: 10,
            padding: 8,
            lineCap: 'round',
            value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
            fill: false,
            type: 'trend',
            autoLineWidth: false,
            $stress_id:1,
            graph_menu:[
                {desc1:'NEED ATTENTION',id:0},
                {desc1:'FAMILY PROBLEMS',id:0},
                {desc1:'DREAMER',id:1},
            ]
        }),
        components: {
            VueCharts,
            SideBar,
        },
    }
</script>

<style scoped>
    .Fira_Sans_Extra_Condensed{
        font-family: 'Fira Sans Extra Condensed', sans-serif;
    }

    .Mouse_Memoirs{
        font-family: 'Mouse Memoirs', sans-serif;
        font-size: 30px;
    }
    .prc--font{
        font-size: 90px;
    }
    .Mulish{
        font-family: 'Mulish', sans-serif;
    }
    .green-color{
        color: #10AC84;
    }
    .name-class{
        font-family: 'Mulish', sans-serif;
        size: 100px;
        justify-content: center;
    }
    .centre{
        justify-content: center;
    }
    .title-class{
        font-family: 'Fira Sans Extra Condensed', sans-serif;
        font-size: 55px;
    }
    .title-class-pen{
        font-family: 'Fira Sans Extra Condensed', sans-serif;
       /* color:#0099CC //blue*/
      /*  color: #c0392b; //red*/
     /*   color: #10AC84  //green*/
        color: #1A7D8C;

    }
    .notes{
        font-family: 'Fira Sans Extra Condensed', sans-serif;
        font-size: 19px;
    }
    .stat-title{
        font-family: 'Mulish', sans-serif;
        font-size: 25px;
    }


</style>
